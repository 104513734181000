
import { Pagation } from "@/composables/usePagation";
import useRenderTable from "@/composables/useRenderTable";
import useRequest from "@/composables/useRequest";
import useFetchSearch from "./composables/useFetchSearch";
import { defineComponent, reactive, toRefs, ref, onActivated } from "vue";
import useCreateAndUpdate from "./composables/useCreateOrUpdate";
import useMessageTip from "@/composables/useMessageTip";
import { RequestParams } from "@/types/request";
import { getEnvirmentSelectList } from "@/api/request-modules/configure";
import { ResponseParams } from "@/types/response";
export default defineComponent({
  name: "environmentVariable",
  setup() {
    const { tipMessage } = useMessageTip();
    const searchParams = reactive({
      name: ""
    });
    const currentPage = ref(1);
    const envList: any = ref([]);
    const tableData = reactive({
      list: [],
      total: 0
    });
    const pageTableRef = ref<any>();
    const stopAutoGetData = ref<boolean>(false);
    // let timer: ReturnType<typeof setTimeout>;

    // 分页以及获取数据
    const getData = async (pagationParams?: Pagation, flag?: boolean, params?: any) => {
      stopAutoGetData.value = flag == undefined ? false : true;
      currentPage.value = (pagationParams && pagationParams.pageIndex) || 1;

      const { response } = useRequest(useFetchSearch, params || searchParams);
      const { pagation } = useRenderTable(response);
      let {
        data: { list, count }
      } = await pagation(pagationParams);
      tableData.total = count;
      tableData.list = list;
    };
    getData();
    const handleConditionSearch = async () => {
      await getData(pageTableRef.value?.getCurrentPage(), true, searchParams);
      stopAutoGetData.value = false;
    };
    const handleName = (val: string) => {
      if (!val) {
        searchParams.name = "";
        handleConditionSearch();
      }
    };
    getEnvirmentSelectList<ResponseParams.ResponseDataSuccess>().then((res) => {
      if (res.code === 200) {
        let data = res.data;
        data.forEach((item: Record<string, any>) => {
          envList.value.push({ id: item.id, value: item.name });
        });
      }
    });
    onActivated(() => {
      getData();
    });

    /**
     * 创建和更新
     */
    const dialogParams = reactive<RequestParams.CreateAndUpdateEnvList>({
      id: 0,
      name: "",
      code: "",
      remark: "",
      is_sys: false,
      sync_env_id: ""
    });
    const { rules, handleCreateAppShowDialog, dialogVisible, handleCreateApp, formRef, handleUpdateApp, handleCloseDialog, handleDelete } = useCreateAndUpdate(
      getData,
      pageTableRef,
      dialogParams
    );

    return {
      handleCloseDialog,
      tipMessage,
      rules,
      handleCreateAppShowDialog,
      handleConditionSearch,
      stopAutoGetData,
      getData,
      ...toRefs(tableData),
      pageTableRef,
      searchParams,
      dialogVisible,
      dialogParams,
      handleCreateApp,
      formRef,
      handleUpdateApp,
      handleDelete,
      handleName,
      envList,
      currentPage
    };
  }
});
